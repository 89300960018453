import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./../../Admin.scss";
import { log, LogLevel } from "../../../utils/LogUtil";
import { setGlobal, GlobalKey, getGlobal } from "../../../utils/GlobalUtil";
import { fetchAPI } from "../../../utils/APIUtil";
import Truncate from "react-truncate";
import {
  SeminarLecture,
  SeminarLectureAttribute,
} from "../../../models/Model.Seminar";
import { UIPopupType } from "../../../store/ui/types";
import { STATUS_CODES } from "http";
import { idText, StringLiteralLike } from "typescript";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import { timingSafeEqual } from "crypto";
import ReactHtmlParser from "react-html-parser";
import { getOS } from "../../../utils/DeviceUtil";
import { isThisHour } from "date-fns";
import * as API from "../../../API.json";
import StringUtil from "../../../utils/StringUtil";
import Textarea from "react-textarea-autosize";
import DownloadUtil from "../../../utils/DownloadUtil";
import { MY_ADDRESS } from "../../../config.json";
import Button from "../../../components/atoms/Button";
import axios from "axios";
import Input from "../../../components/atoms/Input";
import { Flex } from "../../../components/atoms/Layout";

const AdminAPI = {
  DAILY_PHARM_BIZ_LIST: {
    method: "GET",
    path: "/poc/dailypharm/biz",
    contentType: "application/json",
  },
  DAILY_PHARM_COMPANY_UPDATE: {
    method: "PUT",
    path: "/poc/dailypharm/company",
    contentType: "application/json",
  },
  DAILY_PHARM_ACCESS_KEY: {
    method: "GET",
    path: "/poc/dailypharm/key",
    contentType: "application/json",
  },
};

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onSelectRecruit: (recruit: string) => void;
    onSelectOfferId: (id: number) => void;
    onAddCoupon: (bizUserId: string) => void;
    selectedRecruit: any;
  };

type State = {
  companyList: DailyPharmRecruitBizItem[];
  order: string;
  subOrder: string;
  selectedIndex: number;
  editColumn: number;
  editValue: string;
  finished: boolean;
  dateStart: string;
  dateStartWarn: boolean;
  dateEnd: string;
  dateEndWarn: boolean;
  unitCount: number;
  search: string;
};

interface DailyPharmRecruitBizItem {
  code?: string;
  companyId?: number;
  company?: string;
  category?: string;
  title?: string;
  workType?: string; // 모집부문 2
  hireType?: string; // 고용형태
  createdAt?: string;
  checkedAt?: string;
  detail?: any;

  id?: number;
  groupid?: number;
  type?: string; // 모집부문 1
  name?: string; // 기업명
  region1?: string; // 지역(도시)
  region2?: string; // 지역(시구군)
  address?: string;
  position?: any; //{x,y}
  positionX?: number; //{x,y}
  positionY?: number; //{x,y}
  inCharge?: string;
  phone?: string;
  email?: string;
  contact?: string[];
  contactJson?: string;
  lastRecruitedAt?: string;
  lastPharmacyRecruitedAt?: string;
  totalRecruitCnt?: number;
  pharmacistRecruitCnt?: number;
  candidateCnt?: number;
  candidateIn10km?: number;
  candidateIn20km?: number;
  recruits?: DailyPharmRecruit[];
  messagedAt?: any;
  mailAt?: string;

  userId?: number;
  joinedAt?: string;

  lastYmydRecruitedAt?: string;
  lastYmydRecruitId?: number;

  userBizId?: string;

  memo?: string;
}

interface DailyPharmRecruit {}

const fieldOrder = [
  { key: "createdAt", order: "createdAt", name: "공고등록일", type: "date" },
  { key: "type", order: "type", name: "기업구분" },
  { key: "region1", order: "region", name: "지역1", maxLength: 2 },
  { key: "region2", order: "region", name: "지역2" },
  {
    key: "name",
    name: "회사명",
    editable: true,
    maxWidth: 200,
    showValueOnTitle: true,
  },
  { key: "candidateCnt", order: "candidateCnt", name: "알림", type: "number" },
  { key: "code", order: "code", name: "DPCODE" },
  // { key: "id", order: "id", name: "ID" },
  {
    key: "inCharge",
    name: "담당자",
    editable: true,
    maxWidth: 80,
    showValueOnTitle: true,
  },
  { key: "phone", name: "연락처", editable: true },
  {
    key: "email",
    name: "email",
    editable: true,
    maxLength: 10,
    showValueOnTitle: true,
  },
  { key: "messagedAt", order: "messagedAt", name: "최근연락일", type: "date" },
  { key: "mailAt", order: "mailAt", name: "메일발송일", type: "date" },
  {
    key: "userBizId",
    order: "userBizId",
    name: "약문약답ID",
    editable: true,
    maxLength: 8,
  },
  {
    key: "lastYmydRecruitedAt",
    order: "lastYmydRecruitedAt",
    name: "등록일",
    type: "date",
  },
  {
    key: "lastYmydRecruitId",
    order: "lastYmydRecruitId",
    name: "공고ID",
    type: "number",
  },
  {
    key: "memo",
    order: "memo",
    name: "메모",
    editable: true,
    multiline: true,
    showValueOnTitle: true,
  },
];

class DailyPharmRecruitBizList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      companyList: [],
      order: "id",
      subOrder: "",
      selectedIndex: -1,
      editColumn: -1,
      editValue: "",
      finished: false,
      dateStart: getDateStringFromToday({ date: "2023-05-01" }),
      dateStartWarn: false,
      dateEnd: getDateStringFromToday({ days: 1 }),
      dateEndWarn: false,
      unitCount: 200,
      search: "",
    };
  }

  componentDidMount() {
    this.fetchBizRecruitData();
  }

  refresh = () => {
    this.setState({ companyList: [...this.state.companyList] });
  };

  fetchBizRecruitData = async () => {
    let result = await fetchAPI(
      AdminAPI.DAILY_PHARM_BIZ_LIST,
      "",
      {
        count: this.state.unitCount,
        startDate: this.state.dateStart,
        endDate: this.state.dateEnd,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.setState({
        companyList: result.data,
        finished: result.data.length < this.state.unitCount,
      });
    } else {
      this.setState({ companyList: [], finished: true });
    }
    log(
      LogLevel.UI_EVENT,
      "DailyPharmRecruitBizList:fetchBizRecruitData",
      result
    );
  };

  loading = false;
  fetchBizRecruitDataMore = async () => {
    if (this.loading) return;
    this.loading = true;
    let result = await fetchAPI(
      AdminAPI.DAILY_PHARM_BIZ_LIST,
      "",
      {
        start: this.state.companyList.length,
        count: this.state.unitCount,
        startDate: this.state.dateStart,
        endDate: this.state.dateEnd,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.setState({
        companyList: [...this.state.companyList, ...result.data],
        finished: result.data.length < this.state.unitCount,
      });
    } else {
      this.setState({ finished: true });
    }
    this.loading = false;
    log(
      LogLevel.UI_EVENT,
      "DailyPharmRecruitBizList:fetchBizRecruitData",
      result
    );
  };

  onCopy = () => {
    let windowAny: any = window;
    let $ = windowAny.$;
    window.getSelection().removeAllRanges();
    // window.open('data:application/vnd.ms-excel,' + $('#table').html());
    var body = document.getElementById("admin-table-body");
    var range = document.createRange();
    range.selectNode(body);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    this.props.toastPopup.show("클립보드에 저장되었습니다.(안되었을수도 있음)");
  };

  onDownload = () => {
    let csv =
      "ID,타입,회사명,지역1,지역2,담당자,연락처,최근광고일,가입일(ID),구인일(ID),최근구인일,최근약사구인일,구인횟수,약사구인횟수,후보자,후보자10km,후보자20km\r\n";
    csv += this.state.companyList
      .map((item: DailyPharmRecruitBizItem, index) => {
        let rvalue = "";
        rvalue += `${item.id},`;
        rvalue += `${item.type},`;
        rvalue += `${item.name},`;
        rvalue += `${item.region1},`;
        rvalue += `${item.region2},`;
        rvalue += `${item.inCharge},`;
        rvalue += `${item.phone},`;
        rvalue += `${
          item.messagedAt
            ? getDateStringFromToday({ date: item.messagedAt })
            : ""
        },`;
        rvalue += `${
          item.userId
            ? getDateStringFromToday({ date: item.joinedAt }) +
              "(" +
              item.userId +
              ")"
            : ""
        },`;
        rvalue += `${
          item.lastYmydRecruitId
            ? getDateStringFromToday({ date: item.lastYmydRecruitedAt }) +
              "(" +
              item.lastYmydRecruitId +
              ")"
            : ""
        },`;
        rvalue += `${
          item.lastRecruitedAt ? item.lastRecruitedAt.substring(0, 10) : ""
        },`;
        rvalue += `${
          item.lastPharmacyRecruitedAt
            ? item.lastPharmacyRecruitedAt.substring(0, 10)
            : ""
        },`;
        rvalue += `${item.totalRecruitCnt},`;
        rvalue += `${item.pharmacistRecruitCnt},`;
        rvalue += `${item.candidateCnt},`;
        rvalue += `${item.candidateIn10km},`;
        rvalue += `${item.candidateIn20km}\r\n`;

        return rvalue;
      })
      .join("");

    DownloadUtil.downloadCsv(
      csv,
      `DailyPharm-Companies-${this.state.dateStart}-${
        this.state.dateEnd
      }-${new Date().toISOString().substring(0, 10).replace(/-/gi, "")}.csv`
    );
  };

  onSelect = (index) => (e) => {
    if (this.state.selectedIndex == index)
      if (this.state.editColumn == -1) index = -1;
      else return;
    this.setState({ selectedIndex: index, editColumn: -1, editValue: "" });
  };

  onSelectRecruit = (item) => (e) => {
    if (!this.props.onSelectRecruit) return;
    if (
      this.props.selectedRecruit &&
      this.props.selectedRecruit.code == item.code
    )
      this.props.onSelectRecruit(null);
    else this.props.onSelectRecruit(item);
  };

  onEdit = (item: DailyPharmRecruitBizItem, selectedIndex, editColumn) => (
    e
  ) => {
    if (
      selectedIndex == this.state.selectedIndex &&
      editColumn == this.state.editColumn
    ) {
      this.setState({ editColumn: -1, editValue: "" });
    } else {
      let value = item[fieldOrder[editColumn].key];
      if (value) {
        if (fieldOrder[editColumn].type == "date") {
          value = getDateStringFromToday({ date: value });
        }
      } else {
        value = "";
      }
      this.setState({ selectedIndex, editColumn, editValue: value });
    }
  };

  onEditDone = (item: DailyPharmRecruitBizItem) => () => {
    let updates: DailyPharmRecruitBizItem = {
      id: item.id,
    };

    item[fieldOrder[this.state.editColumn].key] = this.state.editValue;
    updates[fieldOrder[this.state.editColumn].key] = this.state.editValue;

    fetchAPI(
      AdminAPI.DAILY_PHARM_COMPANY_UPDATE,
      "",
      null,
      updates,
      getGlobal(GlobalKey.TOKEN)
    );
    this.refresh();
    this.setState({ editColumn: -1, editValue: "" });
  };

  onOrder = (order) => {
    let subOrder = "desc";
    if (order == this.state.order && this.state.subOrder == "desc")
      subOrder = "asc";

    console.log("onOrder", order, subOrder);
    let companyList = this.state.companyList.sort((a, b) => {
      if (order == "region") {
        return (
          (a["region1"] < b["region1"]
            ? -1
            : a["region1"] > b["region1"]
            ? 1
            : a["region2"] < b["region2"]
            ? -1
            : a["region2"] > b["region2"]
            ? 1
            : 0) * (subOrder == "desc" ? -1 : 1)
        );
      } else {
        return (
          (a[order] < b[order] || a[order] == null
            ? -1
            : a[order] > b[order] || b[order] == null
            ? 1
            : 0) * (subOrder == "desc" ? -1 : 1)
        );
      }
    });
    this.setState({ order, subOrder, companyList });
  };

  onDateChange = (e, field) => {
    let dateString = e.target.value;
    let dateWarn = false;
    try {
      let date = Date.parse(dateString);
      if (!date) {
        log(
          LogLevel.UI_EXCEPTION,
          "Admin:DailyPharmRecruitBizList:onDateEndChange",
          dateString
        );
        dateWarn = true;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:DailyPharmRecruitBizList:onDateEndChange",
          dateString,
          date
        );
      }
    } catch (err) {
      log(
        LogLevel.UI_EXCEPTION,
        "Admin:DailyPharmRecruitBizList:onDateEndChange",
        dateString,
        err
      );
      dateWarn = true;
    }
    let newState: any = {};
    (newState[field] = dateString),
      (newState[field + "Warn"] = dateWarn),
      this.setState(newState);
  };

  onJump = (item: DailyPharmRecruitBizItem) => (e) => {
    window.open(
      "http://recruit.dailypharm.com/Offer/OfferView.php?ID=" + item.code,
      "_blank"
    );
  };

  onJumpYmyd = (item: DailyPharmRecruitBizItem) => (e) => {
    if (item.lastYmydRecruitId)
      this.props.onSelectOfferId(item.lastYmydRecruitId);
  };

  onCoupon = (item: DailyPharmRecruitBizItem) => (e) => {
    if (item.userBizId) this.props.onAddCoupon(item.userBizId.toString());
  };

  onCall = (item: DailyPharmRecruitBizItem) => (e) => {
    item.messagedAt = getDateStringFromToday();
    window.open(`tel:${item.phone}}`, "_system");
    item.memo =
      (item.memo || "") +
      "\n" +
      getDateStringFromToday({ separator: "/", year2Digit: true }) +
      " 통화\n";

    let updates: DailyPharmRecruitBizItem = {
      id: item.id,
      memo: item.memo,
      messagedAt: 1,
    };
    fetchAPI(
      AdminAPI.DAILY_PHARM_COMPANY_UPDATE,
      "",
      null,
      updates,
      getGlobal(GlobalKey.TOKEN)
    );
    this.refresh();
  };

  onEmail = (item: DailyPharmRecruitBizItem) => (e) => {
    this.props.confirmPopup.show({
      title: "아래의 정보로 Stibee에 전달하시겠습니까?",
      body: (
        <span>
          기업명 : {item.name} <br />
          담당자명 : {item.inCharge}
          <br />
          email주소 : {item.email}
          <br />
          전화번호 : {item.phone}
          <br />
        </span>
      ),
      backDropDismiss: true,
      onDone: async () => {
        this.props.progressPopup.show();

        try {
          let result = await fetchAPI(
            AdminAPI.DAILY_PHARM_ACCESS_KEY,
            "",
            { id: item.code },
            null,
            getGlobal(GlobalKey.TOKEN)
          );
          let recruitKey = 0;
          if (result && !result.error) {
            recruitKey = result.data;
          }

          if (!recruitKey) {
            throw new Error("키 발급 실패");
          }
          let subscriber: any = {
            company: item.name,
            email: item.email,
            name: item.inCharge,
            phone: item.phone,
            date: getDateStringFromToday(),
            recruitID: item.code,
            recruitKey,
          };

          // await axios.delete(
          //   `https://api.stibee.com/v1/lists/392262/subscribers`,
          //   {
          //     data: [item.email],
          //     url: `https://api.stibee.com/v1/lists/392262/subscribers`,
          //     method: "delete", // POST method
          //     headers: {
          //       AccessToken:
          //         "c54134a71187d7a52a9c27481be3e6c303be4e8643ba5210af47904077dd5d5b7fd060336ae7b2d27fa81458dabafa15948e5e67d5ba05ef08062fa15e3753b7",
          //     },
          //   }
          // );

          await axios.post(
            `https://api.stibee.com/v1/lists/392262/subscribers`,
            {
              eventOccuredBy: "MANUAL",
              confirmEmailYN: "N",
              groupIds: [],
              subscribers: [subscriber],
            },
            {
              url: `https://api.stibee.com/v1/lists/392262/subscribers`,
              method: "post", // POST method
              headers: {
                AccessToken:
                  "c54134a71187d7a52a9c27481be3e6c303be4e8643ba5210af47904077dd5d5b7fd060336ae7b2d27fa81458dabafa15948e5e67d5ba05ef08062fa15e3753b7",
              },
            }
          );
          item.memo =
            (item.memo || "") +
            "\n" +
            getDateStringFromToday({ separator: "/", year2Digit: true }) +
            " 이메일\n";

          item.mailAt = getDateStringFromToday();
          fetchAPI(
            AdminAPI.DAILY_PHARM_COMPANY_UPDATE,
            "",
            null,
            { id: item.id, mailAt: 1 },
            getGlobal(GlobalKey.TOKEN)
          );
          this.refresh();
        } catch (e) {
          this.props.toastPopup.show("이메일 전달 실패 : " + e.message);
          log(
            LogLevel.UI_EXCEPTION,
            "Admin:DailyPharmRecruitBizList:onEmail 이메일 전달 실패 : ",
            e.message
          );
        }
        this.props.progressPopup.hide();
      },
    });
  };

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "DailyPharmRecruitBizList.render",
      this.props,
      this.state
    );

    let more;
    if (!this.state.finished) {
      more = (
        <IonButton expand="full" onClick={this.fetchBizRecruitDataMore}>
          더 불러오기
        </IonButton>
      );
    }

    return (
      <div className="admin-full-container">
        <div className="common-container-row-wrap admin-margin-bottom">
          <div className="admin-title">게시기간</div>
          <input
            className={this.state.dateStartWarn ? "common-color-caution" : ""}
            placeholder="시작일시"
            value={this.state.dateStart}
            onChange={(e) => this.onDateChange(e, "dateStart")}
          />
          <div>~</div>
          <input
            className={this.state.dateEndWarn ? "common-color-caution" : ""}
            placeholder="시작일시"
            value={this.state.dateEnd}
            onChange={(e) => this.onDateChange(e, "dateEnd")}
          />

          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday(),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            오늘
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ days: -1 }),
                dateEnd: getDateStringFromToday(),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            어제
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ weeks: -1 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            1주
          </div>
        </div>
        <div className="common-container-row-wrap admin-margin-bottom">
          <div
            className={
              "admin-toggle" +
              (this.state.unitCount == 200 ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ unitCount: 200 })}
          >
            200row
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.unitCount == 1000 ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ unitCount: 1000 })}
          >
            1000row
          </div>
        </div>
        <Flex alignItems="center">
          <IonButton onClick={this.fetchBizRecruitData}>불러오기</IonButton>
          <IonButton onClick={this.onDownload}>
            다운로드({this.state.companyList.length}건)
          </IonButton>
          <div className="common-flex-grow" />
          <Input
            value={this.state.search}
            onChange={(e) => this.setState({ search: e.target.value })}
            placeholder="검색어"
            onClear={() => this.setState({ search: "" })}
          />
        </Flex>

        <div className="common-container">
          {more}
          <table id="admin-table" className="admin-table">
            <thead className="admin-table-fixed-header">
              <tr>
                {fieldOrder.map((item, index) => {
                  return (
                    <td
                      className="admin-table-label-y"
                      onClick={() => {
                        if (item.order) this.onOrder(item.order);
                      }}
                    >
                      {item.name}{" "}
                      {item.order &&
                        (this.state.order == item.order
                          ? this.state.subOrder == "desc"
                            ? "▼"
                            : "▲"
                          : "")}
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody id="admin-table-body">
              {this.state.companyList.map(
                (item: DailyPharmRecruitBizItem, index) => {
                  // let contact = <td className="admin-table-value"/>;
                  // let email = <td className="admin-table-value"/>;
                  // log(LogLevel.UI_LIFECYCLE, "Admin:DailyPharmRecruitBizList:render", item.phone, !!item.phone, item.contact);
                  // let phone = ""
                  // if(item.phone){
                  //   phone = item.phone;

                  // }else if(item.contact && item.contact.length){
                  //   for(let i=0; i<item.contact.length; i++){
                  //     if(/\d{2,3}-\d{3,4}-\d{4}/.test(item.contact[i]) || /\d{9,11}/.test(item.contact[i])){
                  //       phone =item.contact[i];
                  //       break;
                  //     }
                  //   }
                  // }
                  // if(phone){
                  //   contact = (
                  //     <td className={"admin-table-value" + ((item.messagedAt)?" common-color-caution":"")} onClick={(e) => {
                  //       item.messagedAt = getDateStringFromToday();
                  //       fetchAPI(AdminAPI.DAILY_PHARM_SEND_MESSAGE, item.id.toString(), null, {}, getGlobal(GlobalKey.TOKEN));
                  //       this.setState({companyList :[...this.state.companyList]});
                  //       window.open(`tel:${phone}`,"_system");
                  //     }}>{phone}</td>
                  //   );
                  // }

                  // if(item.contact && item.contact.length){
                  //   for(let i=0; i<item.contact.length; i++){
                  //     if(/[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,4}/.test(item.contact[i])){
                  //       email = (
                  //         <td className={"admin-table-value" + ((this.props.selectedRecruit && this.props.selectedRecruit.code == item.code) ? " admin-table-value-selected" : "") + ((item.messagedAt)?" common-color-caution":"")} onClick={() => {
                  //           // item.messagedAt = getDateStringFromToday();
                  //           // fetchAPI(AdminAPI.DAILY_PHARM_SEND_MESSAGE, item.id.toString(), null, {}, getGlobal(GlobalKey.TOKEN));
                  //           // this.setState({companyList :[...this.state.companyList]});
                  //           // window.open(`tel:${item.phone}`,"_system");
                  //         }}>{item.contact[i]}</td>
                  //       );
                  //       break;
                  //     }
                  //   }
                  // }

                  if (
                    this.state.search &&
                    (!item.name ||
                      item.name.includes(this.state.search) == false) &&
                    (!item.inCharge ||
                      item.inCharge.includes(this.state.search) == false) &&
                    (!item.email ||
                      item.email.includes(this.state.search) == false) &&
                    (!item.phone ||
                      item.phone.includes(this.state.search) == false) &&
                    (!item.memo ||
                      item.memo.includes(this.state.search) == false) &&
                    (!item.region1 ||
                      item.region1.includes(this.state.search) == false) &&
                    (!item.region2 ||
                      item.region2.includes(this.state.search) == false) &&
                    (!item.userBizId ||
                      item.userBizId.includes(this.state.search) == false)
                  )
                    return null;

                  let row = (
                    <tr key={index.toString()} onClick={this.onSelect(index)}>
                      {fieldOrder.map((field, index2) => {
                        let value = item[field.key];
                        let length = field.maxLength || 20;
                        if (value) {
                          if (field.type == "date") {
                            value = getDateStringFromToday({ date: value });
                          }
                        } else {
                          value = "";
                        }

                        let onDoubleClick, onClick, button, title;

                        switch (field.key) {
                          case "id":
                            // double click : detail viewer
                            onDoubleClick = this.onSelectRecruit(item);
                            title = "더블클릭하면 상세정보를 보실수 있어요";
                            break;
                          case "code":
                            // double click : jump to dp
                            onDoubleClick = this.onJump(item);
                            title = "더블클릭하면 해당 컨텐츠로 이동합니다";
                            break;

                          case "phone":
                            // button : call
                            if (value)
                              button = (
                                <div>
                                  <Button
                                    size={"Small"}
                                    type={"Icon"}
                                    variant={"Contained"}
                                    color={"Primary"}
                                    icon="Call"
                                    onClick={this.onCall(item)}
                                  />
                                </div>
                              );
                            break;
                          case "email":
                            if (value)
                              button = (
                                <div>
                                  <Button
                                    size={"Small"}
                                    type={"Icon"}
                                    variant={"Contained"}
                                    color={"Primary"}
                                    icon="Envelope"
                                    onClick={this.onEmail(item)}
                                  />
                                </div>
                              );
                            // button : add to stibee
                            break;
                          case "lastYmydRecruitedAt":
                          case "lastYmydRecruitId":
                            // double click : jump to dp
                            onClick = this.onJumpYmyd(item);
                            title = "클릭하면 해당 컨텐츠로 이동합니다";
                            break;
                          case "userBizId":
                            if (value)
                              button = (
                                <div>
                                  {item.userBizId && (
                                    <Button
                                      size={"Small"}
                                      type={"Icon"}
                                      variant={"Contained"}
                                      color={"Primary"}
                                      icon="Won"
                                      onClick={this.onCoupon(item)}
                                    />
                                  )}
                                </div>
                              );
                            // button : add to stibee
                            break;
                        }

                        if (field.editable) {
                          // double click : edit
                          onDoubleClick = this.onEdit(item, index, index2);
                          title = "더블클릭하면 수정할수 있어요";
                        }

                        if (field.showValueOnTitle) {
                          title = value + "\n\n" + title;
                        }
                        if (
                          this.state.selectedIndex == index &&
                          this.state.editColumn == index2
                        )
                          return (
                            <Input
                              value={this.state.editValue}
                              multiLine={field.multiline}
                              onChange={(e) =>
                                this.setState({ editValue: e.target.value })
                              }
                              onEnter={this.onEditDone(item)}
                            />
                          );

                        return (
                          <td
                            key={index2.toString()}
                            style={{
                              padding: "0px 4px",
                              maxWidth: field.maxWidth,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                            className={
                              "admin-table-value" +
                              (this.state.selectedIndex == index
                                ? " admin-table-value-selected"
                                : "") +
                              (item.messagedAt ? " common-color-highlight" : "")
                            }
                            onDoubleClick={onDoubleClick}
                            onClick={onClick}
                            title={title}
                          >
                            <div className="common-flex-row common-flex-align-center admin-table-value-align-space-between">
                              {field.multiline
                                ? ReactHtmlParser(
                                    value
                                      .toString()
                                      .slice(0, length)
                                      .replace(/\n/gi, "<br/>"),
                                    {}
                                  )
                                : value.toString().slice(0, length)}
                              {button}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                  return row;
                }
              )}
            </tbody>
          </table>
          {more}
          <div style={{ height: "100px" }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  lectures: state.seminar.lectures,
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  filePath: state.board.filePath,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
  loadLecture: (lectureId: number) =>
    actions.seminar.getSeminarLecture(lectureId),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DailyPharmRecruitBizList)
);
