import { setGlobal, GlobalKey, getGlobal } from "./GlobalUtil";
import { log, LogLevel } from "./LogUtil";
import { MY_ADDRESS } from "./../config.json";
import { UserInfo } from "../models/Model.User";
import ABTestUtil, { ABTestFeature } from "./ABTestUtil";
import { ProductInfo } from "../models/Model.Sale";
import { fileURLToPath } from "url";
import { getFilePath } from "../store/board/actions";
import StringUtil from "./StringUtil";
import { DailyQuiz } from "../models/Model.Board";
const windowAny: any = window;

export function sendKakaoInviteMessage(me: UserInfo, type = "NORMAL") {
  log(LogLevel.UI_EVENT, "sendKakaoInviteMessage", type);

  if (type == "NORMAL" && ABTestUtil.isTest(ABTestFeature.UI_INVITE_EVENT)) {
    type = "EVENT";
  }

  switch (type) {
    case "JUNIOR": {
      sendKakaoInviteJuniorMessage(me);
      break;
    }

    case "EVENT": {
      sendKakaoInviteEventMessage(me);
      break;
    }

    case "NORMAL":
    default: {
      sendKakaoInviteNormalMessage(me);
      break;
    }
  }
}

export function sendKakaoBizInviteMessage() {
  let os = getGlobal(GlobalKey.OS);

  // let date = Date.now();
  if (!os || os == "browser") {
    let feed = {
      objectType: "feed",
      content: {
        title: "약문약답 채용서비스를 소개합니다.",
        description:
          "5년차 이하 약사의 88%가 이용중인 약문약답에서 실력있는 약사를 채용하세요.",
        imageUrl: MY_ADDRESS + "/images/ymyd_jobpost_kakao_share.jpg",
        // buttons: [
        //   {
        //     title: "Web에서 보기",
        //     link: {
        //       mobileWebUrl: webUrl,
        //       webUrl: webUrl,
        //     },
        //   },
        //   {
        //     title: "App에서 보기",
        //     link: {
        // androidExecParams: "type=action&action=invite",
        // iosExecParams: "type=action&action=invite",
        //     },
        //   },
        // ],

        link: {
          mobileWebUrl: "https://biz.ymyd.co.kr",
          webUrl: "https://biz.ymyd.co.kr",
        },
        // link: {
        //   androidExecParams: "type=action&action=invite",
        //   iosExecParams: "type=action&action=invite",
        // },
      },
      buttonTitle: "바로가기",
      // buttons: [
      //   {
      //     title: "바로가기",
      //     link: {
      //       webUrl: "https://biz.ymyd.co.kr",
      //       mobileWebUrl: "https://biz.ymyd.co.kr",
      //     },
      //   },
      // ],
    };
    console.log("onInvite", feed);
    try {
      windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, e);
    }
    windowAny.Kakao.Link.sendDefault(feed);
  } else if (windowAny.KakaoCordovaSDK) {
    let feed = {
      objectType: "feed",
      content: {
        title: "약문약답 채용서비스를 소개합니다.",
        desc:
          "5년차 이하 약사의 88%가 이용중인 약문약답에서 실력있는 약사를 채용하세요.",
        imageURL: MY_ADDRESS + "/images/ymyd_jobpost_kakao_share.jpg",
        // buttons: [
        //   {
        //     title: "Web에서 보기",
        //     link: {
        //       mobileWebURL: webUrl,
        //       webURL: webUrl,
        //     },
        //   },
        //   {
        //     title: "App에서 보기",
        //     link: {
        //       androidExecutionParams: "type=view&id=" + this.content.id,
        //       iosExecutionParams: "type=view&id=" + this.content.id,
        //     },
        //   },
        // ],

        link: {
          mobileWebURL: "https://biz.ymyd.co.kr",
          webURL: "https://biz.ymyd.co.kr",
        },
        // link: {
        //   androidExecutionParams: "type=action&action=invite",
        //   iosExecutionParams: "type=action&action=invite",
        // },
      },
      buttonTitle: "바로가기",
      // buttons: [
      //   {
      //     title: "바로가기",
      //     link: {
      //       webURL: "https://biz.ymyd.co.kr",
      //       mobileWebURL: "https://biz.ymyd.co.kr",
      //     },
      //   },
      // ],
    };

    windowAny.KakaoCordovaSDK.sendLinkFeed(
      feed,
      (res) => {
        console.log("Kakao share success", res, feed);
      },
      (res) => {
        console.log("Kakao share fail", res, feed);
      }
    );
  }
}

function sendKakaoInviteNormalMessage(me: UserInfo) {
  let os = getGlobal(GlobalKey.OS);
  const myName = (me && me.name) || "";
  // let date = Date.now();
  if (!os || os == "browser") {
    let feed = {
      objectType: "feed",
      content: {
        title: "from : " + myName + " 약사님",
        description:
          getGlobal(GlobalKey.TOTAL_USER_COUNT) +
          "명의 약사님들이 약문약답에서 지식과 경험을 나누고 있어요.",
        imageUrl: MY_ADDRESS + "/images/ymydgi_20230217.png",
        // buttons: [
        //   {
        //     title: "Web에서 보기",
        //     link: {
        //       mobileWebUrl: webUrl,
        //       webUrl: webUrl,
        //     },
        //   },
        //   {
        //     title: "App에서 보기",
        //     link: {
        // androidExecParams: "type=action&action=invite",
        // iosExecParams: "type=action&action=invite",
        //     },
        //   },
        // ],

        link: {
          mobileWebUrl: "https://service.ymyd.co.kr",
          webUrl: "https://service.ymyd.co.kr",
        },
        // link: {
        //   androidExecParams: "type=action&action=invite",
        //   iosExecParams: "type=action&action=invite",
        // },
      },
    };
    console.log("onInvite", feed);
    try {
      windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, e);
    }
    windowAny.Kakao.Link.sendDefault(feed);
  } else if (windowAny.KakaoCordovaSDK) {
    let feed = {
      objectType: "feed",
      content: {
        title: "from : " + myName + " 약사님",
        desc:
          getGlobal(GlobalKey.TOTAL_USER_COUNT) +
          "명의 약사님들이 약문약답에서 지식과 경험을 나누고 있어요.",
        imageURL: MY_ADDRESS + "/images/ymydgi_20230217.png",
        // buttons: [
        //   {
        //     title: "Web에서 보기",
        //     link: {
        //       mobileWebURL: webUrl,
        //       webURL: webUrl,
        //     },
        //   },
        //   {
        //     title: "App에서 보기",
        //     link: {
        //       androidExecutionParams: "type=view&id=" + this.content.id,
        //       iosExecutionParams: "type=view&id=" + this.content.id,
        //     },
        //   },
        // ],

        link: {
          mobileWebURL: "https://service.ymyd.co.kr",
          webURL: "https://service.ymyd.co.kr",
        },
        // link: {
        //   androidExecutionParams: "type=action&action=invite",
        //   iosExecutionParams: "type=action&action=invite",
        // },
      },
    };

    windowAny.KakaoCordovaSDK.sendLinkFeed(
      feed,
      (res) => {
        console.log("Kakao share success", res, feed);
      },
      (res) => {
        console.log("Kakao share fail", res, feed);
      }
    );
  }
}

function sendKakaoInviteEventMessage(me: UserInfo) {
  let os = getGlobal(GlobalKey.OS);
  const myName = (me && me.name) || "";
  let date = new Date(Date.now() + 9 * 60 * 60 * 1000)
    .toISOString()
    .substring(0, 10);

  if (!os || os == "browser") {
    let feed = {
      objectType: "feed",
      content: {
        title: myName + " 약사님이 약문약답을 초대하셨어요🎉",
        description:
          "[이벤트참여방법] 회원가입을 한 후 이벤트 참여하기 버튼을 꼭! 눌러주세요.",
        imageUrl: MY_ADDRESS + "/images/ymydgi_20230913.png",
        link: {
          androidExecParams: `type=action&action=invite&from=${me.id}&at=${date}`,
          iosExecParams: `type=action&action=invite&from=${me.id}&at=${date}`,
        },
      },
      buttons: [
        {
          title: "회원가입",
          link: {
            webUrl: "https://service.ymyd.co.kr/event",
            mobileWebUrl: "https://service.ymyd.co.kr/event",
          },
        },
        {
          title: "이벤트 참여하기",
          link: {
            androidExecParams: `type=action&action=invite&from=${me.id}&at=${date}`,
            iosExecParams: `type=action&action=invite&from=${me.id}&at=${date}`,
          },
        },
      ],
    };
    try {
      windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, e);
    }
    windowAny.Kakao.Link.sendDefault(feed);
  } else if (windowAny.KakaoCordovaSDK) {
    let feed = {
      objectType: "feed",
      content: {
        title: myName + " 약사님이 약문약답을 초대하셨어요🎉",
        desc:
          "[이벤트참여방법] 회원가입을 한 후 이벤트 참여하기 버튼을 꼭! 눌러주세요.",
        imageURL: MY_ADDRESS + "/images/ymydgi_20230913.png",
        link: {
          androidExecutionParams: `type=action&action=invite&from=${me.id}&at=${date}`,
          iosExecutionParams: `type=action&action=invite&from=${me.id}&at=${date}`,
        },
      },
      buttons: [
        {
          title: "회원가입",
          link: {
            webURL: "https://service.ymyd.co.kr/event",
            mobileWebURL: "https://service.ymyd.co.kr/event",
          },
        },
        {
          title: "이벤트 참여하기",
          link: {
            androidExecutionParams: `type=action&action=invite&from=${me.id}&at=${date}`,
            iosExecutionParams: `type=action&action=invite&from=${me.id}&at=${date}`,
          },
        },
      ],
    };
    windowAny.KakaoCordovaSDK.sendLinkFeed(
      feed,
      (res) => {
        console.log("Kakao share success", res, feed);
      },
      (res) => {
        console.log("Kakao share fail", res, feed);
      }
    );
  }
}

function sendKakaoInviteJuniorMessage(me: UserInfo) {
  let os = getGlobal(GlobalKey.OS);
  const myName = (me && me.name) || "";
  // let date = Date.now();
  if (!os || os == "browser") {
    let feed = {
      objectType: "feed",
      content: {
        title: "from : " + myName + " 약사님",
        description:
          "예비 약사에서 유능한 약사로의 여정, 약문약답에서 시작하세요.",
        imageUrl: MY_ADDRESS + "/images/ymydgi_20241119_junior.jpg",
        // buttons: [
        //   {
        //     title: "Web에서 보기",
        //     link: {
        //       mobileWebUrl: webUrl,
        //       webUrl: webUrl,
        //     },
        //   },
        //   {
        //     title: "App에서 보기",
        //     link: {
        // androidExecParams: "type=action&action=invite",
        // iosExecParams: "type=action&action=invite",
        //     },
        //   },
        // ],

        link: {
          mobileWebUrl: "https://service.ymyd.co.kr/6th_grade",
          webUrl: "https://service.ymyd.co.kr/6th_grade",
        },
        // link: {
        //   androidExecParams: "type=action&action=invite",
        //   iosExecParams: "type=action&action=invite",
        // },
      },
    };
    console.log("onInvite", feed);
    try {
      windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, e);
    }
    windowAny.Kakao.Link.sendDefault(feed);
  } else if (windowAny.KakaoCordovaSDK) {
    let feed = {
      objectType: "feed",
      content: {
        title: "from : " + myName + " 약사님",
        desc: "예비 약사에서 유능한 약사로의 여정, 약문약답에서 시작하세요.",

        imageURL: MY_ADDRESS + "/images/ymydgi_20241119_junior.jpg",
        // buttons: [
        //   {
        //     title: "Web에서 보기",
        //     link: {
        //       mobileWebURL: webUrl,
        //       webURL: webUrl,
        //     },
        //   },
        //   {
        //     title: "App에서 보기",
        //     link: {
        //       androidExecutionParams: "type=view&id=" + this.content.id,
        //       iosExecutionParams: "type=view&id=" + this.content.id,
        //     },
        //   },
        // ],

        link: {
          mobileWebURL: "https://service.ymyd.co.kr/6th_grade",
          webURL: "https://service.ymyd.co.kr/6th_grade",
        },
        // link: {
        //   androidExecutionParams: "type=action&action=invite",
        //   iosExecutionParams: "type=action&action=invite",
        // },
      },
    };

    windowAny.KakaoCordovaSDK.sendLinkFeed(
      feed,
      (res) => {
        console.log("Kakao share success", res, feed);
      },
      (res) => {
        console.log("Kakao share fail", res, feed);
      }
    );
  }
}

export function sendKakaoSalesMessage(product: ProductInfo) {
  let os = getGlobal(GlobalKey.OS);
  if (!os || os == "browser") {
    let feed = {
      objectType: "feed",
      content: {
        title: product.name,
        description: product.description,
        imageUrl: product.image
          ? StringUtil.convertFilePath(product.image)
          : "",
        // buttons: [
        //   {
        //     title: "Web에서 보기",
        //     link: {
        //       mobileWebUrl: webUrl,
        //       webUrl: webUrl,
        //     },
        //   },
        //   {
        //     title: "App에서 보기",
        //     link: {
        // androidExecParams: "type=action&action=invite",
        // iosExecParams: "type=action&action=invite",
        //     },
        //   },
        // ],

        // link: {
        //   mobileWebUrl: "https://service.ymyd.co.kr/6th_grade",
        //   webUrl: "https://service.ymyd.co.kr/6th_grade",
        // },
        link: {
          androidExecParams: `type=jump&path=sale_product&id=${product.id}`,
          iosExecParams: `type=jump&path=sale_product&id=${product.id}`,
        },
      },
    };
    console.log("onInvite", feed);
    try {
      windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, e);
    }
    windowAny.Kakao.Link.sendDefault(feed);
  } else if (windowAny.KakaoCordovaSDK) {
    let feed = {
      objectType: "feed",
      content: {
        title: product.name,
        desc: product.description,

        imageURL: product.image
          ? StringUtil.convertFilePath(product.image)
          : "",
        // buttons: [
        //   {
        //     title: "Web에서 보기",
        //     link: {
        //       mobileWebURL: webUrl,
        //       webURL: webUrl,
        //     },
        //   },
        //   {
        //     title: "App에서 보기",
        //     link: {
        //       androidExecutionParams: "type=view&id=" + this.content.id,
        //       iosExecutionParams: "type=view&id=" + this.content.id,
        //     },
        //   },
        // ],

        // link: {
        //   mobileWebURL: "https://service.ymyd.co.kr/6th_grade",
        //   webURL: "https://service.ymyd.co.kr/6th_grade",
        // },
        link: {
          androidExecutionParams: `type=jump&path=sale_product&id=${product.id}`,
          iosExecutionParams: `type=jump&path=sale_product&id=${product.id}`,
        },
      },
    };

    windowAny.KakaoCordovaSDK.sendLinkFeed(
      feed,
      (res) => {
        console.log("Kakao share success", res, feed);
      },
      (res) => {
        console.log("Kakao share fail", res, feed);
      }
    );
  }
}

export function sendKakaoQuizMessage(dailyQuiz: DailyQuiz) {
  let os = getGlobal(GlobalKey.OS);
  let quiz = dailyQuiz.quizzes[0];
  if (!os || os == "browser") {
    let feed = {
      objectType: "feed",
      content: {
        title: quiz.question,
        description: "",
        imageUrl: MY_ADDRESS + "/images/ymyd_quiz_kakao_share.jpg",
        // buttons: [
        //   {
        //     title: "Web에서 보기",
        //     link: {
        //       mobileWebUrl: webUrl,
        //       webUrl: webUrl,
        //     },
        //   },
        //   {
        //     title: "App에서 보기",
        //     link: {
        // androidExecParams: "type=action&action=invite",
        // iosExecParams: "type=action&action=invite",
        //     },
        //   },
        // ],

        // link: {
        //   mobileWebUrl: "https://service.ymyd.co.kr/6th_grade",
        //   webUrl: "https://service.ymyd.co.kr/6th_grade",
        // },
        link: {
          androidExecParams: "type=jump&path=quiz_daily",
          iosExecParams: "type=jump&path=quiz_daily",
        },
      },
    };
    console.log("onInvite", feed);
    try {
      windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, e);
    }
    windowAny.Kakao.Link.sendDefault(feed);
  } else if (windowAny.KakaoCordovaSDK) {
    let feed = {
      objectType: "feed",
      content: {
        title: quiz.question,
        desc: "",

        imageURL: MY_ADDRESS + "/images/ymyd_quiz_kakao_share.jpg",
        // buttons: [
        //   {
        //     title: "Web에서 보기",
        //     link: {
        //       mobileWebURL: webUrl,
        //       webURL: webUrl,
        //     },
        //   },
        //   {
        //     title: "App에서 보기",
        //     link: {
        //       androidExecutionParams: "type=view&id=" + this.content.id,
        //       iosExecutionParams: "type=view&id=" + this.content.id,
        //     },
        //   },
        // ],

        // link: {
        //   mobileWebURL: "https://service.ymyd.co.kr/6th_grade",
        //   webURL: "https://service.ymyd.co.kr/6th_grade",
        // },
        link: {
          androidExecutionParams: "type=jump&path=quiz_daily",
          iosExecutionParams: "type=jump&path=quiz_daily",
        },
      },
    };

    windowAny.KakaoCordovaSDK.sendLinkFeed(
      feed,
      (res) => {
        console.log("Kakao share success", res, feed);
      },
      (res) => {
        console.log("Kakao share fail", res, feed);
      }
    );
  }
}
