import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonModal,
  IonLoading,
  IonToast,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
  IonFooter,
  IonInput,
  IonItem,
  IonList,
} from "@ionic/react";
import "./ProductOrder.css";
import "./../../pages/ProductSaleOrder.css";
import * as API from "../../API.json";
import { OrderInfo, ProductInfo, ProductStatus } from "../../models/Model.Sale";
import { log, LogLevel } from "../../utils/LogUtil";
import { UIPopupType } from "../../store/ui/types";
import SwipeableViews from "react-swipeable-views";
import ReactHtmlParser from "react-html-parser";
import StringUtil from "../../utils/StringUtil";
import { KeywordType } from "../../models/Model.Medicine";
import ComponentGenerator from "../../ivcomponent/ComponentGenerator";
import LinkMeta from "../LinkMeta";
import drugIcon from "./../../assets/icon/drug.svg";
import { MY_ADDRESS } from "../../config.json";
import { Reference } from "../../models/Model.Board";
import { getGlobal, GlobalKey, setGlobal } from "../../utils/GlobalUtil";
import NumberSelector from "../NumberSelector";
import { UserInfo } from "../../models/Model.User";
import Truncate from "react-truncate";
import { fetchAPI } from "../../utils/APIUtil";

type Props = {
  order: OrderInfo;
  me: UserInfo;
  onCancel: () => void;
  onDone: (value: any) => void;
  onSearchAddress: (name: string, phone: string) => void;
};

type State = {
  recipientName: string;
  recipientPostalCode: string;
  recipientAddress1: string;
  recipientAddress2: string;
  recipientPhone: string;
  pharmacySearchText: string;
  pharmacySearched: any[];
  addressSearchText: string;
  addressSearched: any[];
};

const windowAny: any = window;

class ProductOrderDelivery extends Component<Props, State> {
  onProgress: boolean = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      recipientName: this.props.order.recipientName,
      recipientPostalCode: this.props.order.recipientPostalCode,
      recipientAddress1: this.props.order.recipientAddress1,
      recipientAddress2: this.props.order.recipientAddress2,
      recipientPhone: this.props.order.recipientPhone,
      pharmacySearchText: "",
      pharmacySearched: [],
      addressSearchText: "",
      addressSearched: [],
    };
  }

  componentDidMount() {}

  goBack = (e) => {
    log(LogLevel.UI_ACTION, "ProductOrderDelivery:goBack");
    this.props.onCancel();
  };

  onPharmacySearchTextChange = async (e: CustomEvent) => {
    // log(LogLevel.NONE, e);
    let keywords = e.detail.value;
    this.setState({ pharmacySearchText: keywords });
    if (!keywords) this.setState({ pharmacySearched: [] });
    else {
      let result = await fetchAPI(
        API.USER_SEARCH_PHARMACY,
        "",
        { query: keywords },
        null
      );
      log(
        LogLevel.UI_DATA_LOAD,
        "SearchAddress:onPharmacySearchTextChange",
        result
      );
      if (!result.error) this.setState({ pharmacySearched: result.data });
      else this.setState({ pharmacySearched: [] });
      // log(LogLevel.NONE, result);
    }
  };

  addressSearchTextValidate = (address: string) => {
    if (address.length > 1) {
      //특수문자 제거
      var expText = /[%=><]/;
      if (expText.test(address) == true) {
        address = address.split(expText).join("");
        return false;
      }

      //특정문자열(sql예약어의 앞뒤공백포함) 제거
      var sqlArray = new Array(
        //sql 예약어
        "OR",
        "SELECT",
        "INSERT",
        "DELETE",
        "UPDATE",
        "CREATE",
        "DROP",
        "EXEC",
        "UNION",
        "FETCH",
        "DECLARE",
        "TRUNCATE"
      );

      var regex;
      for (var i = 0; i < sqlArray.length; i++) {
        regex = new RegExp(sqlArray[i], "gi");

        if (regex.test(address)) {
          // alert("\"" + sqlArray[i]+"\"와(과) 같은 특정문자로 검색할 수 없습니다.");
          address = address.replace(regex, "");
          return false;
        }
      }

      return true;
    }

    return false;
  };

  onAddressSearchTextChange = async (e) => {
    let addressSearchText = e.detail.value;

    this.setState({ addressSearchText });
    if (this.addressSearchTextValidate(addressSearchText)) {
      let result = await fetchAPI(
        API.USER_SEARCH_ADDRESS,
        "",
        { query: addressSearchText },
        null
      );
      // console.log(result)
      if (!result.error) {
        if (result.query == addressSearchText)
          this.setState({ addressSearched: result.data });
      } else this.setState({ addressSearched: [] });
      //console.log("valid query", addressSearchText);
    } else {
      //console.log("not valid query", addressSearchText);
      this.setState({ addressSearched: [] });
    }
  };

  onDone = () => {
    this.props.onDone({
      recipientName: this.state.recipientName,
      recipientPostalCode: this.state.recipientPostalCode,
      recipientAddress1: this.state.recipientAddress1,
      recipientAddress2: this.state.recipientAddress2,
      recipientPhone: this.state.recipientPhone.replace(/-/g, ""),
    });
  };

  onAddressSelected = (address: string, postalcode: string) => {
    this.setState({
      recipientAddress1: address,
      recipientPostalCode: postalcode,
      addressSearchText: "",
      addressSearched: [],
    });
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "ProductOrderDelivery.render");

    return (
      <>
        <IonHeader class="common-header" no-border>
          <IonToolbar color="common-header-toolbar">
            <IonButtons slot="start">
              <IonButton onClick={this.goBack}>
                <IonIcon name="arrow-back" mode="ios" />
              </IonButton>
              <IonLabel class="common-header-title" onClick={this.goBack}>
                배송정보 입력
              </IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="common-content-with-header common-background-white common-scroll product-order-additional-information-container">
          <div className="product-order-additional-information-title">
            수령인
          </div>
          <div
            className="product-order-additional-information-input-container"
            onClick={(e) => e.stopPropagation()}
          >
            <IonInput
              class="product-order-additional-information-input"
              placeholder="이름"
              value={this.state.recipientName}
              onIonChange={(e) =>
                this.setState({ recipientName: e.detail.value })
              }
              inputMode="text"
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  (e.target as HTMLElement).blur();
                }
              }}
            />
          </div>
          <div className="product-order-additional-information-title">
            연락처
          </div>
          <div
            className="product-order-additional-information-input-container"
            onClick={(e) => e.stopPropagation()}
          >
            <IonInput
              class="product-order-additional-information-input"
              placeholder="전화번호"
              value={this.state.recipientPhone}
              onIonChange={(e) =>
                this.setState({ recipientPhone: e.detail.value })
              }
              inputMode="tel"
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  (e.target as HTMLElement).blur();
                }
              }}
            />
          </div>
          <div className="product-order-additional-information-title">주소</div>
          <div
            className="product-order-additional-information-input-container"
            onClick={(e) => {
              e.stopPropagation();
              this.props.onSearchAddress(
                this.state.recipientName,
                this.state.recipientPhone.replace(/-/g, "")
              );
            }}
          >
            <IonInput
              class="product-order-additional-information-input"
              placeholder="예) 판교로 "
              readonly={true}
              value={
                this.state.addressSearchText
                  ? this.state.addressSearchText
                  : this.state.recipientAddress1
              }
            />
          </div>
          <div className="product-order-additional-information-title">
            상세주소
          </div>
          <div
            className="product-order-additional-information-input-container"
            onClick={(e) => e.stopPropagation()}
          >
            <IonInput
              class="product-order-additional-information-input"
              placeholder="예) 윤약국 "
              value={this.state.recipientAddress2}
              onIonChange={(e) =>
                this.setState({ recipientAddress2: e.detail.value })
              }
              inputMode="text"
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  (e.target as HTMLElement).blur();
                }
              }}
            />
          </div>
        </div>
        <IonFooter
          class="product-order-additional-information-footer"
          mode="ios"
        >
          <IonButton
            color="common-button"
            class="product-order-additional-information-done-button"
            onClick={this.onDone}
          >
            입력 완료
          </IonButton>
        </IonFooter>
      </>
    );
  }
}

type ItemProps = {
  onSelected: (address: string, postalcode: string) => void;
  item: any;
  key: string;
};

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
});

const mapDispatchToProps = {};

export default ProductOrderDelivery;
