import React from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import {
  IonIcon,
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonMenuToggle,
  IonButton,
} from "@ionic/react";
import { RouteComponentProps, withRouter } from "react-router";
import "./Menu.css";
import backgroundImage from "./../assets/image/menu_background.png";
import seminarIcon from "./../assets/icon/seminar.png";
import chatIcon from "./../assets/icon/chat1x1.png";
import envelopeIcon from "./../assets/icon/Envelope Filled.png";
import myOrderIcon from "./../assets/icon/my-orders.svg";
import { log, LogLevel } from "../utils/LogUtil";
import { UIServiceType } from "../store/ui/types";
import StringUtil from "../utils/StringUtil";
import Truncate from "react-truncate";
import { getGlobal, GlobalKey, setGlobal } from "../utils/GlobalUtil";
import { getDateStringFromToday } from "../utils/TimeUtil";
import { BoardComment } from "../models/Model.Board";
import ProfileImage from "../components/ProfileImage";
import { MY_ADDRESS } from "../config.json";
import ABTestUtil, { ABTestFeature } from "../utils/ABTestUtil";
import ChannelTalkService from "../utils/ChannelTalkService";
import { updateFunctionTypeNode } from "typescript";
import profileImg from "../assets/image/profile_m_1.png";
import { sendKakaoInviteMessage } from "../utils/KakaoUtil";
import AnalyticsUtil from "../utils/AnalyticsUtil";
import Icon from "../components/atoms/Icon";
import COLOR from "../component/_design/color";
import { COLOR_SYSTEM } from "../components/design/design-system";
import { Static } from "../components/atoms/Layout";
import { UserState, UserWorkType } from "../models/Model.User";
import Button from "../components/atoms/Button";

type Props = RouteComponentProps<{}> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    visible?: boolean;
    onClose?: () => void;
  };
type State = {
  status: number; // 0: hidden, 1.shown out, 2: sliding, 3: shown
  animationStyle: string;
  lastChecked: string;
};

class Menu extends React.Component<Props, State> {
  content = null;
  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_EVENT, "Menu:constructor ", props.visible);
    if (props.visible) {
      this.state = {
        status: 3,
        animationStyle: " mmenu-slide-in",
        lastChecked: getGlobal(GlobalKey.MENU_LAST_CHECKED, true),
      };
      setGlobal(GlobalKey.MENU_LAST_CHECKED, getDateStringFromToday({}), true);
    } else
      this.state = {
        status: 0,
        animationStyle: "",
        lastChecked: "",
      };
  }

  componentDidUpdate(prevProps, prevState) {
    log(
      LogLevel.UI_EVENT,
      "Menu:componentDidUpdate ",
      prevProps.visible,
      "=>",
      this.props.visible,
      this.state.status,
      this.content
    );
    if (!prevProps.visible && this.props.visible) {
      this.setState({ status: 1, animationStyle: " mmenu-slide-out" });
      this.onOpen();
    } else if (prevProps.visible && !this.props.visible) {
      this.content.addEventListener("transitionend", this.onAnimationDone);
      this.setState({ status: 2, animationStyle: " mmenu-slide-out" });
    }

    if (prevState.status != this.state.status && this.state.status == 1) {
      setTimeout(() => {
        this.content.addEventListener("transitionend", this.onAnimationDone);
        this.setState({ status: 2, animationStyle: " mmenu-slide-in" });
      }, 100);
    }
  }

  close = () => {
    log(LogLevel.UI_EVENT, "Menu:close ");
    if (ChannelTalkService.isShown) {
      ChannelTalkService.hide();
      return;
    }

    this.props.backKeyControl.popListener();
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  onOpen = () => {
    log(LogLevel.UI_EVENT, "Menu:onOpen ");
    this.setState({
      lastChecked: getGlobal(GlobalKey.MENU_LAST_CHECKED, true),
    });
    setGlobal(GlobalKey.MENU_LAST_CHECKED, getDateStringFromToday({}), true);
    this.props.loadBoardStatistics();
    this.props.loadComment();
    this.props.backKeyControl.pushListener(() => {
      this.close();
      return true;
    });
  };

  onAnimationStart = () => {
    log(LogLevel.UI_EVENT, "Menu:onAnimationStart ", this.state.status);
  };

  onAnimationDone = () => {
    log(LogLevel.UI_EVENT, "Menu:onAnimationDone ", this.state.status);
    if (this.state.status == 2) {
      this.setState({
        status: this.props.visible ? 3 : 0,
        animationStyle: this.props.visible ? " mmenu-slide-in" : "",
      });
    }
    if (this.content) {
      this.content.removeEventListener("transitionend", this.onAnimationDone);
    }
  };

  onChangeProfile = () => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "PROFILE_CHANGE",
      "프로필수정",
      {
        위치: "마이페이지",
      }
    );
    this.props.history.push("/main/user/profile");
  };

  onChat = () => {
    log(
      LogLevel.UI_EVENT,
      "Menu:onChat ",
      ABTestUtil.isTest(ABTestFeature.UI_JOB_POST_CHANNEL_TALK)
    );
    if (ABTestUtil.isTest(ABTestFeature.UI_JOB_POST_CHANNEL_TALK))
      ChannelTalkService.show();
  };

  onInvite = () => {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "INVITE", "초대하기", {
      위치: "마이페이지",
    });
    sendKakaoInviteMessage(this.props.me);
  };

  render() {
    log(LogLevel.UI_EVENT, "Menu:render ", this.state);
    if (!this.state.status) return null;
    let seminarNew;
    if (!this.state.lastChecked)
      seminarNew = <div className="mmenu-submenu-item-new">N</div>;

    let comments = [];
    if (
      this.props.me.recievedComments &&
      this.props.me.recievedComments.length
    ) {
      comments = this.props.me.recievedComments.map(
        (item: BoardComment, index) => {
          let text = item.bodyText;
          if (!text) {
            if (item.fileUrl) text = `[파일 : ${item.fileName}]`;
            else if (item.imageUrl) text = `[이미지]`;
          }
          return (
            <div
              key={index.toString()}
              className="mmenu-latest-commented-item-container-outer"
              onClick={() =>
                this.props.history.push(
                  "/boards/view?id=" +
                    item.boardsContentsGroupId +
                    "&fcmt=" +
                    item.id
                )
              }
            >
              <div className="mmenu-latest-commented-item-container">
                <div className="mmenu-latest-commented-item-profile-container">
                  <ProfileImage
                    className="mmenu-latest-commented-item-profile"
                    profileBase64={item.isHiddenPorfilePost ? profileImg : null}
                    profileUrl={item.usersProfileUrl}
                    anonymous={item.isAnonymous}
                  />
                  <div className="mmenu-latest-commented-item-profile-name">
                    <Truncate lines={1} ellipsis="...">
                      {item.isHiddenPorfilePost
                        ? "질문자"
                        : item.isAnonymous
                        ? "익명사용자"
                        : item.usersNickname}
                    </Truncate>
                  </div>
                </div>
                <div className="mmenu-latest-commented-item-text">
                  <Truncate lines={3} ellipsis="...">
                    {text}
                  </Truncate>
                </div>
              </div>
            </div>
          );
        }
      );
    } else {
      comments.push(
        <div key="0" className="mmenu-latest-commented-item-container">
          <img
            className="mmenu-latest-commented-item-profile-no-comment"
            src={MY_ADDRESS + "/emoticons/sorry.png"}
          />
          <div className="mmenu-latest-commented-item-text">
            <Truncate lines={3} ellipsis="...">
              {"앗! 아직 받은 댓글이 없군요 T -T"}
              <br />
              {"약사님이 먼저 좋아요나 댓글을 남겨보세요~"}
            </Truncate>
          </div>
        </div>
      );
    }

    return (
      <div
        className={
          "mmenu-container" +
          (!this.state.status ? " common-hidden" : "") +
          this.state.animationStyle
        }
        onClick={(e) => {
          e.stopPropagation();
          this.close();
        }}
      >
        <div className="mmenu-inner-container">
          <div
            className={"mmenu-content" + this.state.animationStyle}
            ref={(ref) => (this.content = ref)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className={"mmenu-content-inner"}>
              <img className="mmenu-background" src={backgroundImage} />
              <IonButton color="mmenu-close" onClick={this.close}>
                <IonIcon class="mmenu-close-icon" name="close" />
              </IonButton>
              <IonButton color="mmenu-close2" onClick={this.close}>
                <IonIcon class="mmenu-close-icon" name="close" />
              </IonButton>
              <div className="mmenu-user-info-container">
                <div className="mmenu-user-info-profile-container">
                  <img
                    className="mmenu-user-info-profile"
                    src={StringUtil.convertFilePath(this.props.me.profileUrl)}
                  />
                </div>
                <div className="mmenu-user-info-data-container">
                  <div
                    className="common-container-row common-flex-align-center"
                    style={{ padding: "0px 5px 0px 20px" }}
                  >
                    <div className="mmenu-user-info-data-name">
                      {this.props.me.nickname}
                      {this.props.me.premiumType && (
                        <Static
                          customStyle={{
                            // width: "20px",
                            // height: "20px",
                            // padding: "2px",
                            // borderRadius: "4px",
                            // background: COLOR_SYSTEM.get("Gray")[0],
                            marginLeft: "5px",
                          }}
                        >
                          <img
                            src="/images/sprout2.png"
                            style={{
                              width: "16px",
                              height: "20px",
                              objectFit: "cover",
                            }}
                          />
                        </Static>
                      )}
                    </div>
                    <IonButton
                      expand="block"
                      color="mmenu-profile-change-button"
                      onClick={this.onChangeProfile}
                    >
                      프로필 수정
                    </IonButton>
                  </div>
                  {this.props.me.workType != UserWorkType.UNDERGRADUATE ? (
                    <div className="mmenu-user-info-data-statistics">
                      활동 통계
                    </div>
                  ) : (
                    <Static
                      customStyle={{
                        marginTop: "20px",
                        padding: "0px 20px",
                        width: "100%",
                      }}
                    >
                      <Button
                        style={{ width: "100%" }}
                        size={"Medium"}
                        type={"Text"}
                        variant={"Outlined"}
                        color={"Primary"}
                        onClick={() => {
                          AnalyticsUtil.event(
                            AnalyticsUtil.TYPE_ALL,
                            "PROFILE_CHANGE",
                            "대학생약사인증",
                            {
                              위치: "마이페이지",
                            }
                          );
                          this.props.history.push("/license/check");
                        }}
                        disabled={
                          this.props.me.state == UserState.LICENSE_CHECK
                        }
                        disabledColor={
                          this.props.me.state == UserState.LICENSE_CHECK
                        }
                      >
                        {this.props.me.state == UserState.LICENSE_CHECK
                          ? "약사 인증중..."
                          : "약사 인증 하기"}
                      </Button>
                    </Static>
                  )}
                  {this.props.me.workType != UserWorkType.UNDERGRADUATE && (
                    <div className="common-container-row common-flex-align-center mmenu-user-info-data-statistics-container">
                      <div className="common-flex-column common-flex-align-center mmenu-user-info-data-statistics-item">
                        <div className="mmenu-user-info-data-statistics-item-title">
                          읽기
                        </div>
                        <div className="mmenu-user-info-data-statistics-item-value">
                          {this.props.me.viewCnt}
                        </div>
                      </div>
                      <div className="common-flex-grow" />
                      <div className="common-flex-column common-flex-align-center mmenu-user-info-data-statistics-item">
                        <div className="mmenu-user-info-data-statistics-item-title">
                          댓글
                        </div>
                        <div className="mmenu-user-info-data-statistics-item-value">
                          {this.props.me.commentCnt}
                        </div>
                      </div>
                      <div className="common-flex-grow" />
                      <div className="common-flex-column common-flex-align-center mmenu-user-info-data-statistics-item">
                        <div className="mmenu-user-info-data-statistics-item-title">
                          질문
                        </div>
                        <div className="mmenu-user-info-data-statistics-item-value">
                          {this.props.me.askCnt}
                        </div>
                      </div>
                      <div className="common-flex-grow" />
                      <div className="common-flex-column common-flex-align-center mmenu-user-info-data-statistics-item">
                        <div className="mmenu-user-info-data-statistics-item-title">
                          답변
                        </div>
                        <div className="mmenu-user-info-data-statistics-item-value">
                          {this.props.me.answerCnt}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {this.props.me.workType != UserWorkType.UNDERGRADUATE ? (
                <div className="mmenu-latest-commented-container">
                  <div className="mmenu-latest-commented-title">받은댓글</div>
                  <div className="mmenu-latest-commented-items-container">
                    <div className="mmenu-latest-commented-items-inner-container">
                      {comments}
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ minHeight: "120px" }} />
              )}
              <div className="mmenu-submenu-container">
                {ABTestUtil.isTest(ABTestFeature.UI_PREMIUM_USER) &&
                  this.props.me.workType != UserWorkType.UNDERGRADUATE && (
                    <div
                      className="mmenu-submenu-item-container"
                      onClick={() =>
                        this.props.history.push("/premium/subscribe")
                      }
                    >
                      <Icon
                        size={22}
                        name="Award Filled"
                        color={COLOR_SYSTEM.get("Gray")[0]}
                      />
                      <div className="mmenu-submenu-item-text">
                        약문약답 정기 후원
                      </div>
                    </div>
                  )}
                {ABTestUtil.isTest(ABTestFeature.UI_PREMIUM_USER) &&
                  this.props.me.workType != UserWorkType.UNDERGRADUATE && (
                    <div
                      className="mmenu-submenu-item-container"
                      onClick={() => this.props.history.push("/sale/myorders")}
                    >
                      <IonIcon
                        class="mmenu-submenu-item-icon"
                        src={myOrderIcon}
                      />
                      <div className="mmenu-submenu-item-text">주문 내역</div>
                    </div>
                  )}
                {ABTestUtil.isTest(ABTestFeature.UI_PREMIUM_USER) &&
                  this.props.me.workType != UserWorkType.UNDERGRADUATE && (
                    <div
                      className="mmenu-submenu-item-container"
                      onClick={() => this.props.history.push("/quiz/daily")}
                    >
                      <Icon
                        name="Edit 02 Filled"
                        size={26}
                        color={COLOR_SYSTEM.get("Gray")[0]}
                      />
                      <div className="mmenu-submenu-item-text">오늘의 퀴즈</div>
                    </div>
                  )}

                <div
                  className="mmenu-submenu-item-container"
                  onClick={() => this.props.history.push("/main/bookmark")}
                >
                  <IonIcon
                    class="mmenu-submenu-item-icon"
                    name="bookmark"
                    mode="md"
                  />
                  <div className="mmenu-submenu-item-text">스크랩북</div>
                </div>
                {this.props.me.workType != UserWorkType.UNDERGRADUATE && (
                  <div
                    className="mmenu-submenu-item-container"
                    onClick={() => this.props.history.push("/main/user")}
                  >
                    <IonIcon
                      class="mmenu-submenu-item-icon"
                      name="person"
                      mode="md"
                    />
                    <div className="mmenu-submenu-item-text">내 프로필</div>
                  </div>
                )}
                <div
                  className="mmenu-submenu-item-container"
                  onClick={this.onChat}
                >
                  <img className="mmenu-submenu-item-icon" src={chatIcon} />
                  <div className="mmenu-submenu-item-text">1:1문의</div>
                </div>
                <div
                  className="mmenu-submenu-item-container"
                  onClick={this.onInvite}
                >
                  <img className="mmenu-submenu-item-icon" src={envelopeIcon} />
                  <div className="mmenu-submenu-item-text">친구초대</div>
                </div>

                <div className="common-flex-grow" />
                <div
                  className="mmenu-submenu-item-container mmenu-submenu-item-container-last"
                  onClick={() => this.props.history.push("/main/user/setting")}
                >
                  <IonIcon
                    class="mmenu-submenu-item-icon"
                    name="settings"
                    mode="md"
                  />
                  <div className="mmenu-submenu-item-text">환경 설정</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  loadComment: () => actions.user.getLastRecievedComment(),
  loadBoardStatistics: () => actions.user.updateBoardStatistic(),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
