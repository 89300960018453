import React, { useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BoardSummaryGroup from "../../components/templates/BoardSummaryGroup";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { RootState, actions } from "../../store";
import { UIPopupType, UIServiceType } from "../../store/ui/types";
import { LogLevel, log } from "../../utils/LogUtil";
import {
  BoardContent,
  BoardContentQuiz,
  BoardContentQuizResult,
  BoardContentSummaryGroup,
  DailyQuiz,
} from "../../models/Model.Board";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { fetchAPI } from "../../utils/APIUtil";
import { GlobalKey, getGlobal } from "../../utils/GlobalUtil";
import * as API from "../../API.json";
import BoardUtil from "../../utils/BoardUtil";
import { refreshBoards, updateContent } from "../../store/board/actions";
import { BoardType } from "../../store/board/types";
import AdsUtil from "../../utils/AdsUtil";
import {
  getDateStringFromToday,
  convertDateToDays,
} from "../../utils/TimeUtil";
import DailyQuizHistory from "../../components/templates/DailyQuiz/History";
import DailyQuizCover from "../../components/templates/DailyQuiz/Cover";
import DailyQuizTest from "../../components/templates/DailyQuiz/Test";
import DailyQuizReport from "../../components/templates/DailyQuiz/Report";
import ABTestUtil from "../../utils/ABTestUtil";
import useBottomSheet from "../../hooks/useBottomSheet";
import BottomSheetList from "../../components/molecules/BottomSheetList";
import BottomSheetListItem from "../../components/molecules/BottomSheetListItem";
import { COLOR_SYSTEM } from "../../components/design/design-system";
import { DailyQuizDislikeReasonPopup } from "../../components/templates/DailyQuiz/DislikePopup";
import { set } from "lodash";
import PremiumUserSubscribe from "../PremiumUserSubscribe";
import Viewer from "../Viewer";
import { stat } from "fs";
import { sendKakaoQuizMessage } from "../../utils/KakaoUtil";

const windowAny: any = window;

const DailyQuizPage: React.FC<RouteComponentProps> = ({
  history,
  location,
  match,
}) => {
  const dispatch = useDispatch();
  const bottomSheet = useBottomSheet();

  const me = useSelector((state: RootState) => state.user.me);

  const backKeyControl = useSelector(
    (state: RootState) => state.ui.services[UIServiceType.BACK_CONTROLLER]
  );

  const page = useSelector(
    (state: RootState) => state.board.dailyQuizSolve.page
  );
  const date = useSelector(
    (state: RootState) => state.board.dailyQuizSolve.date
  );
  const result = useSelector(
    (state: RootState) => state.board.dailyQuizSolve.result
  );
  const index = useSelector(
    (state: RootState) => state.board.dailyQuizSolve.index
  );
  const historyStartDays = useSelector(
    (state: RootState) => state.board.dailyQuizSolve.historyStartDays
  );

  const today = getDateStringFromToday();
  const qs = queryString.parse(location.search);

  useEffect(() => {
    log(
      LogLevel.UI_LIFECYCLE,
      "DailyQuiz:useMemo [location.search]",
      date,
      qs.date
    );
    let newDate = qs.date ? (qs.date as string) : today;

    if (newDate != date) {
      dispatch(actions.board.setDailyQuizSolveDate(newDate));
    }
  }, [location.search]);

  const waitingPopup = useSelector(
    (state: RootState) => state.ui.popups[UIPopupType.WAITING_POPUP]
  );

  const dailyQuizId = useMemo(() => {
    return convertDateToDays(date);
  }, [date]);

  const dailyQuiz = useSelector(
    (state: RootState) => state.board.dailyQuizs[dailyQuizId]
  );

  const dailyQuizHistory = useSelector(
    (state: RootState) => state.board.dailyQuizs
  );

  useEffect(() => {
    log(
      LogLevel.UI_LIFECYCLE,
      "DailyQuiz:useEffect [] start ",
      date,
      dailyQuiz
    );
    if (backKeyControl)
      backKeyControl.setListener(() => {
        log(LogLevel.UI_LIFECYCLE, "DailyQuiz:back key callback");
        goBack();
      });

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "DAILY_QUIZ_START",
      "데일리퀴즈_진입",
      {}
    );
  }, []);

  useEffect(() => {
    log(
      LogLevel.UI_LIFECYCLE,
      "DailyQuiz:useEffect [dailyQuiz, date] start ",
      date,
      dailyQuiz
    );
    if (!dailyQuiz || !dailyQuiz.quizzes) {
      log(LogLevel.UI_LIFECYCLE, "DailyQuiz:useEffect [dailyQuiz] load", date);
      dispatch(actions.board.loadDailyQuiz(date));
    }
  }, [dailyQuiz, date]);

  const goBack = () => {
    history.goBack();
    setTimeout(() => {
      dispatch(actions.board.setDailyQuizSolveClear());
    }, 100);

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "DAILY_QUIZ_END",
      "데일리퀴즈_나가기",
      {
        date,
        page,
        index,
      }
    );
  };

  const onClickQuizReference = (quiz: BoardContentQuiz) => {
    log(LogLevel.UI_ACTION, "DailyQuiz:onClickQuizReference ", quiz);
    let url = "/boards/view?id=" + quiz.contentId;

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "DAILY_QUIZ_REFERENCE",
      "데일리퀴즈_원문보기",
      {
        id: quiz.contentId,
        date,
        page,
        index,
      }
    );
    history.push(url);

    // bottomSheet.show({
    //   body: (
    //     <Viewer
    //       contentId={quiz.contentId}
    //       onBack={() => bottomSheet.close()}
    //       recommendDisabled={true}
    //     />
    //   ),
    //   maxHeight: "100vh",
    // });
  };

  const onClickQuizLike = (quiz: BoardContentQuiz) => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "DAILY_QUIZ_REFERENCE",
      "데일리퀴즈_퀴즈좋아요",
      {
        dqid: dailyQuiz.id,
        qid: quiz.id,
        date,
      }
    );
    log(LogLevel.UI_ACTION, "DailyQuiz:onClickQuizLike ", quiz);

    let newQuiz: BoardContentQuiz = { id: quiz.id };

    if (quiz.liked) {
      newQuiz.liked = false;
      newQuiz.likeCnt = quiz.likeCnt - 1;
      fetchAPI(
        API.DAILY_QUIZ_DELETE_LIKE,
        "",
        {
          boardContentQuizId: quiz.id, // 관련된 GPT 퀴즈의 ID
          // dailyQuizId: dailyQuiz.id, // 해당 퀴즈의 일일 ID
        },
        null,
        getGlobal(GlobalKey.TOKEN)
      );
    } else {
      newQuiz.liked = true;
      newQuiz.likeCnt = quiz.likeCnt + 1;
      fetchAPI(
        API.DAILY_QUIZ_POST_LIKE,
        "",
        null,
        {
          boardContentQuizId: quiz.id, // 관련된 GPT 퀴즈의 ID
          dailyQuizId: dailyQuiz.id, // 해당 퀴즈의 일일 ID
        },
        getGlobal(GlobalKey.TOKEN)
      );
    }

    dispatch(actions.board.setDailyQuizQuiz(newQuiz, dailyQuiz.id));
  };

  const onClickQuizDislike = (quiz: BoardContentQuiz) => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "DAILY_QUIZ_REFERENCE",
      "데일리퀴즈_싫어요",
      {
        dqid: dailyQuiz.id,
        qid: quiz.id,
        date,
      }
    );
    log(LogLevel.UI_ACTION, "DailyQuiz:onClickQuizDislike ", quiz);

    let newQuiz: BoardContentQuiz = { id: quiz.id };

    if (quiz.disliked) {
      newQuiz.disliked = false;
      newQuiz.dislikeCnt = quiz.dislikeCnt - 1;
      fetchAPI(
        API.DAILY_QUIZ_DELETE_DISLIKE,
        "",
        {
          boardContentQuizId: quiz.id, // 관련된 GPT 퀴즈의 ID
          // dailyQuizId: dailyQuiz.id, // 해당 퀴즈의 일일 ID
        },
        null,
        getGlobal(GlobalKey.TOKEN)
      );

      dispatch(actions.board.setDailyQuizQuiz(newQuiz, dailyQuiz.id));
    } else {
      // newQuiz.disliked = true;
      // newQuiz.dislikeCnt = quiz.dislikeCnt + 1;
      bottomSheet.show({
        title: {
          value: "불편한 점을 말씀해주시면 개선하겠습니다.",
          align: "left",
        },
        body: (
          <DailyQuizDislikeReasonPopup
            onDone={(text) => {
              newQuiz.disliked = true;
              newQuiz.dislikeCnt = quiz.dislikeCnt + 1;

              fetchAPI(
                API.DAILY_QUIZ_POST_DISLIKE,
                "",
                null,
                {
                  boardContentQuizId: quiz.id, // 관련된 GPT 퀴즈의 ID
                  dailyQuizId: dailyQuiz.id, // 해당 퀴즈의 일일 ID
                  valueStr: text,
                },
                getGlobal(GlobalKey.TOKEN)
              );
              bottomSheet.close();

              newQuiz.disliked = true;
              newQuiz.dislikeCnt = quiz.dislikeCnt + 1;
              dispatch(actions.board.setDailyQuizQuiz(newQuiz, dailyQuiz.id));
            }}
          />
        ),
      });
    }
  };

  const onClickPremium = () => {
    let url = "/premium/subscribe";

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "DAILY_QUIZ_OREMIUM",
      "데일리퀴즈_프리미엄가입",
      {
        date,
        page,
        // url: url,
      }
    );
    history.push(url, { from: "back" });

    // bottomSheet.show({
    //   body: <PremiumUserSubscribe />,
    // });
  };

  const onClickKakaoShare = () => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "DAILY_QUIZ_KAKAO_SHARE",
      "데일리퀴즈_카카오공유",
      {
        dailyQuizID: dailyQuiz.id,
        date: dailyQuiz.date,
      }
    );
    sendKakaoQuizMessage(dailyQuiz);
  };

  const onClickDailyQuiz = (dailyQuiz: DailyQuiz) => {
    let url = "/quiz/daily?date=" + dailyQuiz.date;
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "DAILY_QUIZ_REFERENCE",
      "데일리퀴즈_이전데일리퀴즈풀기",
      {
        url: url,
        date: dailyQuiz.date,
      }
    );

    history.replace(url);
  };

  const onSolveTest = (result: BoardContentQuizResult) => {
    log(LogLevel.UI_ACTION, "DailyQuizPage:onSolveTest", result);
    dispatch(actions.board.addDailyQuizSolveResult(result));
  };

  const onNextTest = () => {
    dispatch(actions.board.progressDailyQuizSolve());
  };

  const onSetHistoryDays = (days) => {
    dispatch(actions.board.setDailyQuizHistoryDays(days));
  };

  const onDoneTest = async (dailyQuizResult: DailyQuiz) => {
    log(LogLevel.UI_ACTION, "DailyQuizPage:onDoneTest", dailyQuizResult);
    waitingPopup.show();
    dailyQuizResult.date = dailyQuiz.date;
    dailyQuizResult.id = dailyQuiz.id;

    try {
      await fetchAPI(
        API.DAILY_QUIZ_SAVE_RESULT,
        "",
        null,
        { results: dailyQuizResult.results },
        getGlobal(GlobalKey.TOKEN)
      );
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, "DailyQuizPage:onDoneTest", e);
      if (ABTestUtil.isTest())
        alert(`오류가 발생했습니다. 다시 시도해주세요. ${e.message}`);
    }

    if (dailyQuiz.score > dailyQuizResult.score) {
      dailyQuizResult.score = dailyQuiz.score;
    }

    dispatch(actions.board.setDailyQuiz(dailyQuizResult));
    dispatch(actions.board.setDailyQuizSolvePage("REPORT"));
    waitingPopup.hide();

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "DAILY_QUIZ_MOVE",
      "데일리퀴즈_결과이동",
      {
        date,
      }
    );
  };

  const onLoadHistory = (options: { startDate: string; endDate: string }) => {
    dispatch(actions.board.loadDailyQuizList(options));
  };

  log(LogLevel.UI_LIFECYCLE, "DailyQuiz:render [index] ", date, me);

  if (page === "COVER") {
    return (
      <DailyQuizCover
        premium={me.premiumType}
        dailyQuizList={dailyQuizHistory}
        dailyQuiz={dailyQuiz}
        onLoadHistory={onLoadHistory}
        onClickDailyQuiz={onClickDailyQuiz}
        onPremium={onClickPremium}
        onStart={() => {
          dispatch(actions.board.setDailyQuizSolvePage("TEST"));
        }}
        setHistoryStartDays={onSetHistoryDays}
        historyStartDays={historyStartDays}
        onClose={goBack}
      />
    );
  } else if (page == "TEST") {
    return (
      <DailyQuizTest
        dailyQuiz={dailyQuiz}
        onDone={onDoneTest}
        onGoBack={goBack}
        onQuizReference={onClickQuizReference}
        index={index}
        solve={result}
        onProgress={onNextTest}
        onSolve={onSolveTest}
      />
    );
  } else if (page == "REPORT") {
    return (
      <DailyQuizReport
        dailyQuiz={{ ...dailyQuiz, ...result }}
        premium={me.premiumType}
        dailyQuizList={dailyQuizHistory}
        onLoadHistory={onLoadHistory}
        onClickDailyQuiz={onClickDailyQuiz}
        onQuizReference={onClickQuizReference}
        onQuizLike={onClickQuizLike}
        onQuizDislike={onClickQuizDislike}
        onClickPremium={onClickPremium}
        onKakaoShare={onClickKakaoShare}
        onFinish={goBack}
        setHistoryStartDays={onSetHistoryDays}
        historyStartDays={historyStartDays}
      />
    );
  }
  return <div></div>;
};

export default withRouter(DailyQuizPage);
